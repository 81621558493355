<template>
  <div>
    <Hero />
    <HowItWorks />
    <BeProvider />
    <CTA />
  </div>
</template>

<script>
import Hero from './components/hero'
import HowItWorks from './components/how-it-works'
import BeProvider from './components/be-provider'
import CTA from './components/cta'

export default {
  name: 'ApiDeveloper',
  components: {
    Hero,
    HowItWorks,
    BeProvider,
    CTA
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'OpenAPIHub Suite for API Developer | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/api-developer' }
      ],
      meta: [
        { name: 'description', content: 'Learn the best API practices and get access to OpenAPIHub community APIs.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'OpenAPIHub Suite for API Developer | OpenAPIHub' },
        { property: 'og:description', content: 'Learn the best API practices and get access to OpenAPIHub community APIs.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/api-developer' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
