<template>
  <div class="position-relative">
    <div class="container space-bottom-3">
      <div class="rounded overflow-hidden space-top-2 space-top-lg-0 pl-5 pl-md-8 shadow" style="background-color:#EAF4FD">
        <div class="row justify-content-lg-between align-items-lg-center gutters">
          <div class="col-lg-6 col-xl-7">
            <div class="mb-4 pr-4">
              <h2>{{ $t('APIDeveloperPage.BeProviderSection.Title') }}</h2>
              <p>{{ $t('APIDeveloperPage.BeProviderSection.Description') }}</p>
            </div>
            <router-link
              :to="{ path: `/${$route.params.lang}/api-provider`}"
              class="btn btn-primary mr-2 mb-2"
            >{{ $t('APIDeveloperPage.BeProviderSection.Button') }}
            </router-link>
            <!-- <a href="javascript:;" data-toggle="modal" data-target="#videoModal" class="btn btn-outline-primary btn-white mb-2" target="_blank"><i class="fas fa-play-circle mr-1" /> {{ $t('APIDeveloperPage.BeProviderSection.WatchButton') }}</a> -->
          </div>
          <!-- Video -->
          <div class="col-lg-6 col-xl-5 space-top-1 space-top-sm-2 ml-auto">
            <div data-gtm-att="ga-intro-video-card" class="card shadow overflow-hidden mx-lg-auto bg-light">
              <!-- <a href="javascript:;" data-toggle="modal" data-target="#videoModal" class="video-player"> -->
              <div class="video-player">
                <img class="img-fluid video-player-preview" src="@/assets/svg/illustrations/sample-portal-home.svg">
                <!-- <div class="video-player-btn video-player-centered">
                  <span class="video-player-icon video-player-icon-pill">
                    <i class="fas fa-play-circle mr-2 font-size-2" /> {{ $t('APIDeveloperPage.BeProviderSection.WatchButton') }}
                  </span>
                </div> -->
                <div class="embed-responsive embed-responsive-4by3">
                  <div id="oahVideoIframe" />
                </div>
              </div>
            </div>
            <div id="videoModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="videoModalTitle" aria-hidden="true">
              <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 id="videoModalTitle" class="modal-title" />
                    <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                      <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                      </svg>
                    </button>
                  </div>
                  <div class="modal-body p-2 px-sm-4 pt-0 pb-4">
                    <div style="padding:52% 0 0 0;position:relative;">
                      <iframe :src="$t('AcademyPage.VideoSection.WhatIsOAH.Video')" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="$t('AcademyPage.VideoSection.WhatIsOAH.Title')" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')

export default {
  name: 'BeProvider',
  mounted() {
    $('#videoModal').on('hidden.bs.modal', function(e) {
      $('#videoModal iframe').attr('src', $('#videoModal iframe').attr('src'))
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
