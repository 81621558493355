<template>
  <div class="position-relative overflow-hidden bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/general-bg-7.svg`)})`}">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 mx-auto text-center">
        <span class="d-block small font-weight-bold text-cap mb-2">{{ $t('APIDeveloperPage.HeroSection.Subtitle') }}</span>
        <h1 class="">{{ $t('APIDeveloperPage.HeroSection.Title') }}</h1>
        <p class="lead mb-4">{{ $t('APIDeveloperPage.HeroSection.Description') }}</p>
        <a id="" href="https://hub.openapihub.com/login" class="btn btn-primary mr-1 mr-sm-2 mb-2" target="_blank">{{ $t('APIDeveloperPage.HeroSection.Button1') }}</a>
        <a id="ga-OW02-02-03" href="https://hub.openapihub.com" class="btn btn-outline-primary btn-white mr-1 mr-sm-0 mb-2" target="_blank">{{ $t('APIDeveloperPage.HeroSection.Button2') }}</a>
      </div>
      <div class="w-lg-95 mx-auto">
        <img class="img-fluid" src="@/assets/img/screenshots/oah-screens.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss" scoped>
</style>
